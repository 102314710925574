import React, { useEffect, useState, useMemo } from "react"
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import useFetch from "./codeHelper/useFetch"
// import AboutUs from "./AboutUs"
import NavBar from "./component/NavBar"
import Home from "./Home"
import LoanProcess from "./LoanProcess"
import LoanServices from "./LoanServices"
import HotTopics from "./HotTopics"
import FloatBtnList from "./component/FloatBtnList"
import TU from "./LoanServicesPages/TU"
import SmeLoan from "./LoanServicesPages/SmeLoan"
import PrivateLending from "./LoanServicesPages/PrivateLending"
import Mortgage from "./LoanServicesPages/Mortgage"
import Creditcard from "./LoanServicesPages/Creditcard"
import OnlineLoan from "./LoanServicesPages/OnlineLoan"
import PrivateLoan from "./PrivateLoan"
import MortgageLoan from "./mortgageLoan/MortgageLoan"
import BalanceTransfer from "./balanceTransfer/BalanceTransfer"
import AboutUs from "./pages/AboutUs"
import Contact from "./pages/Contact"
import Thankyou from "./pages/Thankyou"
import Application from "./pages/Application"
// import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import chatbot_icon from "./component/chatbot/chatbotman.png"

import ApplicationBtn from "./component/ApplicationBtn"
import Disclaimer from "./Disclaimer"
import Ordinance from "./Ordinance"
import PrivacyPolicy from "./PrivacyPolicy"
import ReactChatbot from "./component/chatbot/ReactChatbot"
import { ENV } from "./config"
import ApplyForm from "./component/ApplyForm"
import CollectInFoStatement from "./pages/CollectInfoStatement"
import GoogleTagManager from "./component/GoogleTagManager"
import GoogleAdsConversion from "./component/GoogleAdsConversion"
import { findDescriptionByTitle } from "./codeHelper/utls"
// import Application from "./component/Application/Application"

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const content_style = { marginTop: "0rem" }

  const { data } = useFetch(ENV + "/api/v1/content")
  const post_result = useFetch(ENV + "/api/v1/post")

  const memoizedData = useMemo(() => data, [data])
  const memoizedPostResult = useMemo(() => post_result, [post_result])
  const [showBottomNav, setShowBottomNav] = useState(true)

  const pathname = window.location.pathname

  const MetaData = data?.MT
  const GtagID = findDescriptionByTitle(MetaData, "GtagID")
  const GtagLabel = findDescriptionByTitle(MetaData, "GtagLabel")

  return (
    <BrowserRouter>
      <div className="App">
        {/* <ToastContainer autoClose={10000} hideProgressBar /> */}
        {/* <ReactChatbot /> */}
        <FloatBtnList />
        {GtagID && <GoogleTagManager id={GtagID} />}
        {GtagID && GtagLabel && (
          <GoogleAdsConversion id={GtagID} label={GtagLabel} />
        )}

        {/* {showBottomNav && (
          <ApplicationBtn
            isBottomNav={true}
            setShowBottomNav={setShowBottomNav}
          />
        )} */}
        <NavBar content={memoizedData} postData={memoizedPostResult.data} />
        {pathname === "/" ? null : <div className="top-space" />}
        {/* <div className="top-space"></div> */}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div style={content_style}>
                <Home content={memoizedData} />
              </div>
            }
          />
          <Route
            exact
            path="/私人貸款"
            element={<PrivateLoan content={memoizedData} />}
          />
          {/* <Route
            exact
            path="/application/:content"
            element={<Application setShowBottomNav={setShowBottomNav} data={data} />}
          /> */}
          <Route
            exact
            path="/按揭及業主貸款"
            element={<MortgageLoan content={memoizedData} />}
          />
          <Route
            exact
            path="/結餘轉戶"
            element={<BalanceTransfer content={memoizedData} />}
          />
          <Route
            exact
            path="/about"
            element={<AboutUs content={memoizedData} />}
          />
          <Route
            exact
            path="/contact"
            element={<Contact content={memoizedData} />}
          />

          <Route
            exact
            path="/application"
            element={<Application content={data} />}
          />

          {/* <Route
            exact
            path="/loanservices"
            element={<LoanServices content={data} />}
          /> */}
          {/* <Route exact path="/loanprocess" element={<LoanProcess />} /> */}
          <Route
            exact
            path="/hottopics/:order/:title"
            element={
              <HotTopics
                content={memoizedData}
                postData={memoizedPostResult.data}
              />
            }
          />
          {/* <Route exact path="/hottopics" element={<HotTopics content={data} postData ={post_result.data} />} /> */}
          <Route exact path="/thankyou" element={<Thankyou content={data} />} />

          <Route
            exact
            path="/免責聲明"
            element={<Disclaimer content={memoizedData} />}
          />
          <Route
            exact
            path="/隱私政策"
            element={<PrivacyPolicy content={memoizedData} />}
          />
          <Route
            exact
            path="/個人資料收集聲明"
            element={<CollectInFoStatement content={memoizedData} />}
          />
          <Route
            exact
            path="/放債人條例"
            element={<Ordinance content={memoizedData} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
