import css from "./aboutus.module.css"
import HomeSection5 from "../page_section/HomeSection5"
import Footer from "../component/Footer"
import { Helmet } from "react-helmet"
import bg from "../images/aboutus/aboutus_bg.jpg"
import circle_logo from "../images/circle_logo.png"
import square from "../images/squaregp.png"
import { useMediaQuery } from "react-responsive"
import { Button } from "react-bootstrap"
// import GoogleAdsConversion from "../component/GoogleAdsConversion"
import { findDescriptionByTitle } from "../codeHelper/utls"

const Thankyou = ({ content }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 544px)" })
  const MetaData = content?.MT
  const GtagID = findDescriptionByTitle(MetaData, "GtagID")
  const GtagLabel = findDescriptionByTitle(MetaData, "GtagLabel")

  return (
    <>
      <Helmet>
        <title>{content?.MT[11].metaTitle}</title>
        <meta name="description" content={content?.MT[11].metaDescription} />
        <meta name="keywords" content={content?.MT[11].metaKeywords} />
      </Helmet>
      {/* <GoogleAdsConversion id={GtagID} label={GtagLabel} /> */}

      {!isMobile && (
        <div className={css.container}>
          <img src={square} alt="" className={css.bg_square} />

          <div className={css.banner}>
            <h1>多謝閣下提交申請</h1>
          </div>

          <div className={css.content}>
            <img src={circle_logo} alt="" width={"300px"} className="mb-5" />
            <p style={{ fontWeight: "bold", fontSize: "21px" }}>
              申請程序已經完成，
              <br />
              我們會盡快審批您的貸款申請。
            </p>
            <Button variant="contained" href="/">
              ← 返回主頁
            </Button>
          </div>
          {/* <HomeSection5 /> */}
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={css.container}>
          <div className={css.banner}>
            <h1>多謝閣下提交申請</h1>
          </div>

          <div className={css.content}>
            <img src={square} alt="" className={css.bg_square} />
            <img src={circle_logo} alt="" width={"200"} className="mb-5" />
            <p style={{ fontWeight: "bold", fontSize: "21px" }}>
              申請程序已經完成，
              <br />
              我們會盡快審批您的貸款申請。
            </p>
            <Button variant="contained" href="/">
              ← 返回主頁
            </Button>
          </div>
          <div className="mt-5">
            <Footer />
          </div>
        </div>
      )}
    </>
  )
}

export default Thankyou
